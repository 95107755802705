import { UserDto } from '@/domain/user/dtos/user.dto';
import LocalStorageKey from '@/shared/utils/constants/local-storage-key.constants';
import {
  parseAuthFn,
  removeLocalStorageFn,
  setLocalStorageFn,
} from '@/shared/utils/functions/localstorage.functions';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  public setLocalStorage(itemName: string, item: any): void {
    return setLocalStorageFn(itemName, item);
  }

  public getLocalStorageItem(itemName: string): any {
    if (localStorage.getItem(itemName) === 'undefined') return null;

    return parseAuthFn(localStorage.getItem(itemName)!);
  }

  public removeLocalStorageData(): void {
    removeLocalStorageFn();
  }

  /**
   * Obtiene el usuario almacenado en el localstorage.
   */
  public getUser(): UserDto | null {
    const rawUser = localStorage.getItem(LocalStorageKey.USER);
    if (!rawUser) {
      return null;
    }
    return JSON.parse(rawUser) as UserDto;
  }
}
